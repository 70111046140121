import { Col, LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { RoleAttributeCategory } from '@traba/types'
import { useMemo } from 'react'
import { useAttributes } from 'src/hooks/useAttributes'
import { useWorkerProfile } from 'src/hooks/useWorkerProfile'
import { filterNonOpsLevelingLanguages } from 'src/utils/attributeUtils'
import { WorkerAttributesSection } from './Qualifications/WorkerAttributesSection'

interface Props {
  workerId: string
}

export function QualificationsTab(props: Props) {
  const { workerId } = props
  const {
    attributes: roleAttributes,
    isLoading: isLoadingAttributes,
    isError: isErrorFetchingAttributes,
  } = useAttributes()
  const {
    workerProfileAttributes,
    isLoading: isLoadingWorkerAttributes,
    isError: isErrorFetchingWorkerAttributes,
  } = useWorkerProfile(workerId)

  const attributes = useMemo(
    () => roleAttributes.filter(filterNonOpsLevelingLanguages),
    [roleAttributes],
  )

  if (isLoadingWorkerAttributes || isLoadingAttributes) {
    return <LoadingSpinner />
  }

  if (
    isErrorFetchingWorkerAttributes ||
    isErrorFetchingAttributes ||
    !workerProfileAttributes?.length
  ) {
    return (
      <Text variant="error">
        {'There was an error fetching worker attributes.'}
      </Text>
    )
  }

  return (
    <Col gap={theme.space.xs}>
      <Row gap={theme.space.xs}>
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Forklift'}
          svgIconName="equipment"
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[RoleAttributeCategory.Forklift]}
        />
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Lifting'}
          svgIconName="dumbbell"
          svgIconSize={16}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[RoleAttributeCategory.Lifting]}
        />
      </Row>
      <Row gap={theme.space.xs}>
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Manufacturing'}
          svgIconName="manufacturing"
          svgIconSize={20}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[
            RoleAttributeCategory.MANUFACTURING_AND_PRODUCTION,
          ]}
        />
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Languages'}
          svgIconName="language"
          svgIconSize={16}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[RoleAttributeCategory.Languages]}
        />
      </Row>
      <Row gap={theme.space.xs}>
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Traits'}
          svgIconName="user"
          svgIconSize={20}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[RoleAttributeCategory.TRAITS]}
        />
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Misc'}
          svgIconName="user"
          svgIconSize={20}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[
            RoleAttributeCategory.Equipment,
            RoleAttributeCategory.Gear,
          ]}
        />
      </Row>
      <Row>
        <WorkerAttributesSection
          workerId={workerId}
          sectionTitle={'Custom Hidden Attributes'}
          svgIconName="user"
          svgIconSize={20}
          attributes={attributes}
          workerProfileAttributes={workerProfileAttributes}
          attributeCategories={[RoleAttributeCategory.Responsibilities]}
        />
      </Row>
    </Col>
  )
}
